import Header from 'components/Header'
import navData from 'components/Navigation/navigation.json'
import { validateHeaderItem } from 'utils/validations'

export default function HeaderContainer({
  disabledRoutes,
  disabledElements,
  content,
  noHeaderCta,
  showNav = true,
  showSubNav = true,
  showSignIn = true,
  customPhoneData = null,
}) {
  let signIn = null
  let link = null
  if (content) {
    signIn = content?.signIn
    link = content?.link
  }
  let linkUrl = link?.linkHref
  let linkText = link?.linkText?.json?.content[0]?.content[0]?.value
  let signInLinkUrl = signIn?.linkHref
  let signInLinkText = signIn?.linkText?.json?.content[0]?.content[0]?.value

  const mapContentfulToHeader = (data) => {
    if (!data) return navData.mainNavigation
    return validateHeaderItem(data.navItemsCollection.items).map((navItem) => {
      if (navItem?.uiComponent === 'Grid') {
        return {
          label: navItem.name,
          subNavMenus: [
            {
              menuTitle: '',
              menuItems: validateHeaderItem(navItem.gridItemsCollection.items).map((subNav) => {
                return {
                  label: subNav?.name,
                  description: subNav?.linkText?.json.content[0].content[0].value,
                  testid: subNav?.linkText?.json.content[0].content[0].value,
                  url: subNav?.linkHref,
                }
              }),
            },
          ],
          testid: navItem.name,
        }
      } else {
        return {
          label: navItem.name,
          url: navItem.linkHref,
          testid: navItem?.name,
        }
      }
    })
  }

  const mainNavigation = mapContentfulToHeader(content)

  return (
    <Header
      disabledElements={disabledElements}
      disabledRoutes={disabledRoutes}
      mainNavigation={mainNavigation}
      signInLinkUrl={signInLinkUrl}
      signInLinkText={signInLinkText}
      linkUrl={linkUrl}
      linkText={linkText}
      noHeaderCta={noHeaderCta}
      showNav={showNav}
      showSubNav={showSubNav}
      showSignIn={showSignIn}
      customPhoneData={customPhoneData}
    />
  )
}
