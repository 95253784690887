import { urlHeaderSchema, urlFooterSchema } from 'constants/validation-types'

const validateHeaderItem = (menuItems = []) => {
  return menuItems?.filter((item) => {
    if (item?.uiComponent === 'Grid') {
      return item?.gridItemsCollection.items?.filter((subMenuItem) =>
        urlHeaderSchema.isValidSync(subMenuItem)
      )
    } else {
      return urlHeaderSchema.isValidSync(item)
    }
  })
}

const validateFooterItem = (menuItems = []) => {
  return menuItems?.filter((item) => urlFooterSchema.isValidSync(item))
}

export { validateFooterItem, validateHeaderItem }
